import React from 'react';
import SectionWrapper from '../containers/SectionWrapper';
import SectionContainer from '../containers/SectionContainer';

function PageHeader({ text }) {
	return (
		<SectionWrapper>
			<SectionContainer margin="my-10" css="relative overflow-hidden">
				<div className="w-fit h-full bg-primary border-r-8 border-secondary border-solid">
					<div className="p-10">
						<h2>{text}</h2>
					</div>
				</div>
			</SectionContainer>
		</SectionWrapper>
	);
}

export default PageHeader;
