import React from 'react';
import { Link } from 'react-router-dom';
import { MdExtension, MdBusiness, MdHelpCenter, MdEmail } from 'react-icons/md';
import { FaBlog } from 'react-icons/fa';
import { ImPriceTag } from 'react-icons/im';
import { BsCaretRightFill } from 'react-icons/bs';

function Hamburger() {
	return (
		<div className="flex-none md:hidden">
			<div className="dropdown dropdown-end">
				<label tabIndex={0} className="btn btn-ghost btn-circle">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="white">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
					</svg>
				</label>
				<ul tabIndex={0} className="menu dropdown-content mt-3 shadow bg-base-400 rounded-box w-fit text-4xl !text-white font-bold">
					<li>
						<Link to="https://chrome.google.com/webstore/detail/aichatpilot/fmbanjkafaebpafddbihomoeodjgaomk?hl=en&authuser=1" target="_blank" className="py-14 px-20">
							<span className="mr-6">
								<BsCaretRightFill />
							</span>{' '}
							Get Now
						</Link>
					</li>
					<li>
						<Link to="/how-it-works" className="py-14 px-20">
							<span className="mr-6">
								<MdExtension />
							</span>{' '}
							How it Works
						</Link>
					</li>
					<li>
						<Link to="/enterprise" className="py-14 px-20">
							<span className="mr-6">
								<MdBusiness />
							</span>{' '}
							Enterprise
						</Link>
					</li>
					<li>
						<Link to="/pricing" className="py-14 px-20">
							<span className="mr-6">
								<ImPriceTag />
							</span>{' '}
							Pricing
						</Link>
					</li>
					<li>
						<Link to="/" className="py-14 px-20">
							<span className="mr-6">
								<MdHelpCenter />
							</span>{' '}
							FAQ
						</Link>
					</li>
					<li>
						<Link to="/blog" className="py-14 px-20">
							<span className="mr-6">
								<FaBlog />
							</span>{' '}
							Blog
						</Link>
					</li>
					<li>
						<Link to="/contact" className="py-14 px-20">
							<span className="mr-6">
								<MdEmail />
							</span>{' '}
							Contact
						</Link>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Hamburger;
