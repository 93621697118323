import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { compressAccurately } from 'image-conversion';
import AuthContext from '../../Context/AuthContext/AuthContext';
import MainContainer from '../../components/containers/MainContainer';
import PageHeader from '../../components/header/PageHeader';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import Button from '../../components/buttons/Button';
import { db } from '../../firebase.config';
import TextEditor from '../../components/textEditor/TextEditor';
import { auth, storage } from '../../firebase.config';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

function CreateBlog() {
	const { user, authLoading } = useContext(AuthContext);
	const [post, setPost] = useState({
		thumbnail: null,
		title: '',
		content: '',
		snippet: '',
		timestamp: serverTimestamp(),
	});
	const [uploading, setUploading] = useState(false);
	const [content, setContent] = useState();
	const navigate = useNavigate();

	const submitPost = async () => {
		try {
			setUploading(true);
			setPost(prevState => {
				return {
					...prevState,
					content: content,
				};
			});
			const postToUpload = cloneDeep(post);
			postToUpload.content = content;
			if (!postToUpload.title || postToUpload.title === '') {
				console.log(`No title`);
				return;
			}
			if (!postToUpload.content || postToUpload.content === '') {
				console.log('No post content!');
				return;
			}
			if (!postToUpload.snippet || postToUpload.snippet === '') {
				console.log('No post snippet!');
				return;
			}
			if (!postToUpload.thumbnail) {
				console.log(`No thumbnail`);
				return;
			}
			await setDoc(doc(db, 'blog', postToUpload.title), postToUpload);
			setUploading(false);
			navigate(`/${postToUpload.title}`);
		} catch (error) {
			console.log(error);
			setUploading(false);
		}
	};

	/**
	 * handles uploading multiple images to the DB.
	 * @param {*} image
	 * @returns
	 */
	const uploadImage = async images => {
		for (const image in images) {
			if (image.size > 5242880) {
				console.log(`${image.name} is too big! Must be smaller than 5mb`);
				return;
			}
		}

		/**
		 * Stores the image on firebase
		 * @param {*} image
		 * @returns
		 */
		const storeImage = async image => {
			// Store images in firebase
			return new Promise((resolve, reject) => {
				const fileName = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;

				const storageRef = ref(storage, 'images/' + fileName);
				const uploadTask = uploadBytesResumable(storageRef, image);

				// Listen for state changes, errors, and completion of the upload.
				uploadTask.on(
					'state_changed',
					snapshot => {
						// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						console.log('Upload is ' + progress + '% done');
						switch (snapshot.state) {
							case 'paused':
								console.log('Upload is paused');
								break;
							case 'running':
								console.log('Upload is running');
								break;
						}
					},
					error => {
						// A full list of error codes is available at
						// https://firebase.google.com/docs/storage/web/handle-errors
						switch (error.code) {
							case 'storage/unauthorized':
								reject(error);
								break;
							case 'storage/canceled':
								reject(error);
								break;
							case 'storage/unknown':
								reject(error);
								break;
						}
					},
					() => {
						// Upload completed successfully, now we can get the download URL
						getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
							resolve(downloadURL);
						});
					}
				);
			});
		};

		/**
		 * Compress all images to 400kb
		 * @param {*} image
		 * @returns
		 */
		const compressImg = async img => {
			try {
				return new Promise((resolve, reject) => {
					const compressImg = async img => {
						console.log(img);
						const compressed = await compressAccurately(img, 400);
						return compressed;
					};

					const compressesImage = compressImg(img);
					resolve(compressesImage);
				});
			} catch (error) {
				console.log(error);
			}
		};

		const compressedImages = await Promise.all([...images].map(img => compressImg(img)));

		const imgUrls = await Promise.all([...compressedImages].map(img => storeImage(img))).catch(() => {
			return;
		});

		setPost(prevState => {
			return {
				...prevState,
				thumbnail: imgUrls[0],
			};
		});
		return imgUrls;
	};

	const handleChange = e => {
		setPost(prevState => {
			return {
				...prevState,
				[e.target.id]: e.target.value,
			};
		});
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Create Blog Post</title>
				<link rel="canonical" href={`https://aichatpilot.com/create-blog-post`} />
			</Helmet>
			<MainContainer>
				<PageHeader text="Create Blog Post" />
				<SectionWrapper>
					<SectionContainer padding="p-10" margin="my-20" color="bg-base-500">
						{uploading && <h2 className="text-success">Uploading</h2>}
						{!authLoading && user && (
							<form className="flex flex-col gap-10">
								{/* Title */}
								<label className="input-group text-3xl">
									<span>Title</span>
									<input id="title" type="text" onChange={handleChange} placeholder="Enter Post Title" className="input input-lg text-3xl w-full" />
								</label>

								{/* Thumbnail */}
								<div className="flex flex-row gap-4 items-center">
									<label className="flex flex-row w-fit text-3xl">
										<span className="rounded-l-lg bg-base-300 p-4">Thumbnail</span>
										<input type="file" id="build-image" max="1" accept=".jpg,.png,.jpeg" className="file-input w-full max-w-xs mb-6 2k:file-input-lg text-slate-200" onChange={e => uploadImage(e.target.files)} />
									</label>
									{post.thumbnail && <img src={post.thumbnail} alt="" className="w-56" />}
								</div>

								{/* Snippet */}
								<div>
									<p>Snippet</p>
									<textarea id="snippet" onChange={handleChange} placeholder="Enter Post Snippet" className="textarea text-3xl w-full h-44" />
								</div>

								{/* Content */}
								<div className="div">
									<p>Content</p>
									<TextEditor setState={setContent} />
								</div>

								<Button text="Submit" icon="upload" color="btn-primary" size="w-fit" onClick={submitPost} />
							</form>
						)}
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default CreateBlog;
