import React from 'react';

function SectionContainer({ color, margin, padding, align, css, position, display, children }) {
	return (
		<div
			className={`${display ? display : 'flex'} flex-col ${align ? align : ''} justify-center w-full text-white ${color ? color : 'bg-base-300'} ${margin ? margin : ''} ${css ? css : ''} ${position ? position : ''} ${padding ? padding : ''} `}
		>
			{children}
		</div>
	);
}

export default SectionContainer;
