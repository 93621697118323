import { db } from '../firebase.config';
import { doc, getDoc, getDocs, collection, where, query, limit, startAfter, deleteDoc, orderBy } from 'firebase/firestore';

/**
 * Fetches a single blog post.
 * @param {*} post
 * @returns
 */
export const fetchBlogPost = async post => {
	try {
		const postData = await getDoc(doc(db, 'blog', post));
		const blogPost = postData.data();
		return blogPost;
	} catch (error) {
		console.log(error);
		return error;
	}
};

/**
 * Handles fetching blog posts. Takes in an optional 'last fetched' value for pagination
 * @param {*} lastFetched
 * @returns
 */
export const fetchBlogPosts = async lastFetched => {
	try {
		const blogPostsRef = collection(db, 'blog');
		let blogPosts = [];
		let constraints = [limit(process.env.BLOG_POSTS_FETCH_NUM), orderBy('timestamp', 'desc')];

		if (lastFetched) constraints.push(startAfter(lastFetched));

		let q = query(blogPostsRef, ...constraints);
		const blogsSnap = await getDocs(q);

		blogsSnap.forEach(blogPost => {
			const post = blogPost.data();
			post.id = blogPost.id;
			blogPosts.push(post);
		});

		return blogPosts;
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const deleteBlogPost = async post => {
	try {
		await deleteDoc(doc(db, 'blog', post));
	} catch (error) {
		console.log(error);
		return error;
	}
};
