import React from 'react';
import Button from '../buttons/Button';
import { Link } from 'react-router-dom';
import LogoIcon from '../../assets/logo-icon.png';
import { SiAircanada } from 'react-icons/si';

function Footer() {
	return (
		<>
			<footer className="footer p-10 bg-base-200 text-base-content">
				<div className="prose prose-2xl flex flex-col items-center w-full">
					<span className="footer-title">PRODUCT</span>
					<Button type="ahref" href="/pricing" size="w-full" color="btn-ghost" text="Pricing" />
					<Button type="ahref" href="/how-it-works" size="w-full" color="btn-ghost" text="How it Works" />
					<Button type="ahref" href="/enterprise" size="w-full" color="btn-ghost" text="Enterprise" />
					<Button type="ahref" href="/faq" size="w-full" color="btn-ghost" text="F.A.Q" />
				</div>
				<div className="prose prose-2xl flex flex-col items-center w-full">
					<span className="footer-title">Company</span>
					<Button type="ahref" href="/contact" size="w-full" color="btn-ghost" text="Contact" />
					<Button type="ahref" href="/blog" size="w-full" color="btn-ghost" text="Blog" />
					<Button type="ahref" href="/media-kit" size="w-full" color="btn-ghost" text="Media kit" />
				</div>
				<div className="prose prose-2xl flex flex-col items-center w-full">
					<span className="footer-title">Legal</span>
					<Button type="ahref" href="/terms" size="w-full" color="btn-ghost" text="Terms of use" />
					<Button type="ahref" href="/privacy" size="w-full" color="btn-ghost" text="Privacy policy" />
				</div>
			</footer>

			<footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-400">
				<div className="flex flex-row items-center w-full">
					<img src={LogoIcon} alt="AiChatPilot Logo" className="w-20" />
					<p className="ml-5 flex flex-row gap-4 items-center">
						AiChatPilot. Your internet Copilot. Made in Canada{' '}
						<span>
							<SiAircanada />
						</span>
					</p>
				</div>
				<div className="md:place-self-center md:justify-self-end">
					<div className="grid grid-flow-col gap-20">
						<Link to="https://twitter.com/aichatpilot" target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" className="fill-current">
								<path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
							</svg>
						</Link>
						<Link to="https://www.facebook.com/people/Aichatpilot/100091328373860/" target="_blank">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" className="fill-current">
								<path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
							</svg>
						</Link>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;
