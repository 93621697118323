import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { functions } from '../../firebase.config';
import { httpsCallable } from 'firebase/functions';
import MainContainer from '../../components/containers/MainContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import SectionContainer from '../../components/containers/SectionContainer';
import PageHeader from '../../components/header/PageHeader';
import Button from '../../components/buttons/Button';
import EmailGraphic from '../../assets/email_graphic.png';
import Spinner1 from '../../components/spinner/Spinner1';

function Contact() {
	const [message, setMessage] = useState({
		email: '',
		message: '',
	});
	const [error, setError] = useState({
		email: '',
		message: '',
	});
	const [messageSent, setMessageSent] = useState(false);
	const [sendingMessage, setSendingMessage] = useState(false);

	/**
	 * Handles updating the state
	 * @param {*} e
	 */
	const handleSetMessage = e => {
		setMessage(prevState => {
			return {
				...prevState,
				[e.target.id]: e.target.value,
			};
		});
	};

	/**
	 * Handles submitting a message
	 * @param {*} e
	 */
	const submitMessage = async e => {
		try {
			setError({
				email: '',
				message: '',
			});
			setSendingMessage(true);
			setMessageSent(false);
			if (message.email === '') {
				setError(prevState => {
					return {
						...prevState,
						email: 'You need to enter a valid email.',
					};
				});
				console.log(`No email address`);
				return;
			}
			if (message.message === '') {
				setError(prevState => {
					return {
						...prevState,
						message: 'You need to enter a message.',
					};
				});
				console.log(`No message`);
				return;
			}
			if (message.message.length > 750) {
				setError(prevState => {
					return {
						...prevState,
						message: 'Message too long, must be less than 750 characters.',
					};
				});
				return;
			}
			// call the GPT function to create our text
			const sendMessage = httpsCallable(functions, 'contact');
			sendMessage(message)
				.then(async res => {
					if (res.data.response === 'Message Sent') {
						setMessageSent(true);
						setMessage({
							email: '',
							message: '',
						});
						setSendingMessage(false);
					} else {
						setError(prevState => {
							return {
								...prevState,
								message: `Something went wrong, please try again. If the issue persists, try reaching out to contact@aichatpilot.com through another email provider.`,
							};
						});
					}
				})
				.catch(err => {
					throw new Error(err);
				});
		} catch (error) {
			console.log(error);
			setSendingMessage(false);
		}
	};

	//---------------------------------------------------------------------------------------------------//
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Contact</title>
				<link rel="canonical" href={`https://aichatpilot.com/contact`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="Contact" />
				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="mb-20">
						<div className="w-full flex flex-col lg:flex-row gap-20 place-content-between items-center">
							<form className="flex flex-col bg-base-400 p-10 rounded-lg max-w-6xl">
								<h3 className="mb-10">Have a question or need to get in touch? Reach out below.</h3>
								<p className="text-slate-300">Your Email</p>
								<input onChange={handleSetMessage} type="email" id="email" value={message.email} placeholder="you@example.com" className="input input-lg text-3xl mb-10" />
								{error.email !== '' && <p className="text-error">{error.email}</p>}
								<p className="text-slate-300">
									Messge <span className={`${message.message.length > 750 ? 'text-error' : 'text-slate-500'} italic ml-10`}>Max: {750 - message.message.length}</span>{' '}
								</p>
								<textarea onChange={handleSetMessage} name="" id="message" rows="4" className="textarea mb-10 text-3xl" value={message.message} placeholder="Enter your message here"></textarea>
								{error.message !== '' && <p className="text-error">{error.message}</p>}
								{messageSent && <p className="text-success">Message sent. We'll get back to you as soon as we can</p>}
								{sendingMessage && <Spinner1 />}
								<Button onClick={submitMessage} text="Send" icon="email" color="btn-primary" size="w-fit" />
							</form>
							<img src={EmailGraphic} alt="Email Graphic" className="w-full sm:w-3/4 lg:w-1/2 lg:max-w-4xl object-cover" />
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Contact;
