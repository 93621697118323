import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import MainContainer from '../../components/containers/MainContainer';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import PageHeader from '../../components/header/PageHeader';
import Button from '../../components/buttons/Button';
import LogoIcon from '../../assets/logo-icon.png';
import Chrome from '../../assets/chrome_buy.png';

function Pricing() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Pricing</title>
				<link rel="canonical" href={`https://aichatpilot.com/pricing`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="Pricing" />

				<SectionWrapper>
					<SectionContainer color="bg-base-100 my-20" align="items-center">
						<div className="card w-full sm:w-3/4 lg:w-2/5 bg-base-500 shadow-xl">
							<figure className="bg-accent">
								<img src={LogoIcon} alt="Aichatpilot Logo" className="w-1/2" />
							</figure>
							<div className="card-body gap-20">
								<div>
									<h2 className="card-title text-center">
										$4.99 <span className="text-slate-500 font-thin">/mo</span>
									</h2>
									<p className="italic text-slate-500">
										Per user. There may be additional charges from openAI based on usage. See OpenAI's{' '}
										<Link to="https://openai.com/pricing" target="_blank" className="link">
											Pricing
										</Link>
										for more information. Cancel at any time. Full refund within 7 days of purchase.
									</p>
								</div>
								<p>Get access to the full extension & all of its capabilities. Get instant access to chatGPT on any webpage.</p>
								<Link to="/">
									<img src={Chrome} alt="" />
								</Link>
								<div className="card-actions">
									<Button icon="right2" text="Buy Now" color="btn-primary" size="w-full" type="ahref" href="https://chrome.google.com/webstore/detail/aichatpilot/fmbanjkafaebpafddbihomoeodjgaomk?hl=en&authuser=1" target="_blank" />
								</div>
							</div>
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Pricing;
