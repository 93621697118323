import React from 'react';
import { Helmet } from 'react-helmet';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import PageHeader from '../../components/header/PageHeader';
import MainContainer from '../../components/containers/MainContainer';
import LogoWhite from '../../assets/logo-icon.png';
import LogoWhiteBackground from '../../assets/logo-icon-circle.png';
import TextLogo from '../../assets/logo-text.png';

function MediaKit() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Media Kit</title>
				<link rel="canonical" href={`https://aichatpilot.com/media-kit`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="Media Kit" />
				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="my-20">
						<div className="flex flex-col mb-36 gap-10 bg-base-300 p-10 rounded-xl relative">
							<h5 className="text-center">
								We provide the following logos for you to use at your discretion. These resources are intended to assist you in using our brand and materials, such as our logo, content, and trademarks, without the need for individual
								legal agreements.
							</h5>
							<h5 className="text-center">
								If you wish to use our marks in a manner that is not covered by these guidelines, or for press-related inquiries, please contact us at contact@aichatpilot.com and include an example of your intended use. For any
								press-related inquiries, please also contact us at the same email address.
							</h5>
						</div>

						<div className="flex flex-row flex-wrap gap-10">
							<flex className="flex flex-col gap-10 items-center bg-base-300 rounded-lg p-10">
								<p>Icon Logo</p>
								<img src={LogoWhite} alt="" className="w-44" />
							</flex>
							<flex className="flex flex-col gap-10 items-center bg-base-300 rounded-lg p-10">
								<p>Icon Logo w/ Background</p>
								<img src={LogoWhiteBackground} alt="" className="w-44" />
							</flex>
							<flex className="flex flex-col gap-10 items-center bg-base-300 rounded-lg p-10">
								<p>Text Logo</p>
								<img src={TextLogo} alt="" className="w-96" />
							</flex>
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default MediaKit;
