import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MainContainer from '../../components/containers/MainContainer';
import PageHeader from '../../components/header/PageHeader';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import { fetchBlogPosts } from '../../utilities/fetchBlogPost';
import BlogCard from '../../components/blog/BlogCard';

function Blog() {
	const [blogPosts, setBlogPosts] = useState([]);
	const [blogLoading, setBlogLoading] = useState(true);

	useEffect(() => {
		fetchBlogPosts().then(posts => {
			setBlogPosts(posts);
			setBlogLoading(false);
		});
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Blog</title>
				<link rel="canonical" href={`https://aichatpilot.com/blog`} />
			</Helmet>
			<MainContainer>
				<PageHeader text="Blog" />
				<SectionWrapper>
					<SectionContainer color="bg-base-100">
						<h2 className="mb-10">Whats happening</h2>
						<div className="flex flex-row flex-wrap gap-10 3xl:gap-20">
							{blogPosts.map((post, i) => {
								return <BlogCard key={i} post={post} />;
							})}
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
			;
		</>
	);
}

export default Blog;
