import React from 'react';
import MainContainer from '../../components/containers/MainContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import SectionContainer from '../../components/containers/SectionContainer';
import PageHeader from '../../components/header/PageHeader';
import { Helmet } from 'react-helmet';

function Privacy() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Privacy Policy</title>
				<link rel="canonical" href={`https://aichatpilot.com/privacy`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="Privacy Policy" />
				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="my-20">
						<div className="flex flex-col w-full gap-10">
							<h4>Effective March 20,2023</h4>
							<p>We at Aichatpilot take the privacy of our users very seriously. This policy outlines what information we collect, why we collect it, and how we use it.</p>
							<h5>Information We Collect</h5>
							<ul className="ml-10">
								<li>
									• When a user enters a prompt into our extension, the prompt is passed to our server and then immediately passed on to OpenAI to generate a response. At no point do we store or collect any user data, including the
									prompts entered & responses generated.
								</li>
								<li>• We do not collect any personally identifiable information, such as names, email addresses, or IP addresses.</li>
							</ul>
							<h5>How We Use Your Information</h5>
							<p>
								We use the information we collect solely for the purpose of improving the functionality of Aichatpilot. This includes analyzing usage data to identify trends and improve the chatbot's performance, and monitoring API
								usage to ensure compliance with OpenAI's terms of service.
							</p>
							<p>We will never share your information with third parties, except as required by law.</p>
							<h5>Data Security</h5>
							<p>We take data security very seriously and have implemented industry-standard measures to protect your information. However, no system is completely secure, and we cannot guarantee the security of your information.</p>
							<h5>Third-Party Services</h5>
							<p>Aichatpilot uses the OpenAI API to generate responses to user prompts. OpenAI's privacy policy can be found at https://openai.com/privacy/.</p>
							<h5>Changes to this Policy</h5>
							<p>We may update this privacy policy from time to time. We will notify users of any significant changes via email or through the Aichatpilot extension.</p>
							<h5>Contact Us</h5>
							<p>If you have any questions or concerns about our privacy policy, please contact us at [insert contact email here].</p>
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Privacy;
