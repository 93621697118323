import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../buttons/Button';

function BlogCard({ post }) {
	const date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(post.timestamp.seconds * 1000);

	//---------------------------------------------------------------------------------------------------//
	return (
		<Link to={`/blog/${post.id}`} className="card bg-base-100 border-b-4 border-solid border-base-800  max-w-3xl cursor-pointer">
			<figure>
				<img src={post.thumbnail} alt={post.title} />
			</figure>
			<div className="card-body">
				<h2 className="card-title hover:underline">{post.title}</h2>
				<p className="card-snippet">{post.snippet}</p>
				<p className="italic text-right text-slate-500">{date}</p>
			</div>
		</Link>
	);
}

export default BlogCard;
