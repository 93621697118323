import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
//---------------------------------------------------------------------------------------------------//
import MainContainer from '../../components/containers/MainContainer';
import Button from '../../components/buttons/Button';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import PageHeader from '../../components/header/PageHeader';

function HowItWorks() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - How it Works</title>
				<link rel="canonical" href={`https://aichatpilot.com/how-it-works`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="How it Works" />
				<SectionWrapper color="bg-base-300">
					<SectionContainer margin="my-20" padding="py-10 px-4">
						<h5>
							Aichatpilot is a chrome extension that enables you to generate high-quality text content from any webpage using the power of OpenAI's GPT-3 language model. To use it, simply type "gpt:" followed by your desired prompt into
							any text area on the page, and Aichatpilot will automatically insert chatGPTs response into the same box.{' '}
						</h5>
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="my-20" padding="px-4">
						<h2 className="mb-10">Get Started</h2>

						{/* 1 */}
						<div className="flex flex-col gap-20">
							<div className="flex flex-row gap-10 items-center">
								<div className="w-24 h-24 shrink-0 rounded-full bg-primary text-4xl font-bold flex items-center justify-center">1</div>
								<p>
									Download and install the extension from the Google Web Store{' '}
									<Link to="/" target="_blank" className="link link-accent">
										here.
									</Link>
								</p>
							</div>

							{/* 2 */}
							<div className="flex flex-row gap-10 items-center">
								<div className="w-24 h-24 shrink-0 rounded-full bg-primary text-4xl font-bold flex items-center justify-center">2</div>
								<p>Open up the browser Extension on the top-right of Google Chrome (you may need to click the Puzzle piece to see it).</p>
							</div>

							{/* 3 */}
							<div className="flex flex-row gap-10 items-center">
								<div className="w-24 h-24 shrink-0 rounded-full bg-primary text-4xl font-bold flex items-center justify-center">3</div>
								<div className="flex flex-col gap-4">
									<p>Subscribe to AiChatPilot by clicking the "Purchase to get started" button</p>
									<p className="text-slate-300">After you fill out your payment information and subscribe, close/open the extension and you'll be able to access all of its features</p>
								</div>
							</div>

							{/* 4 */}
							<div className="flex flex-row gap-10 items-center">
								<div className="w-24 h-24 shrink-0 rounded-full bg-primary text-4xl font-bold flex items-center justify-center">4</div>
								<div className="flex flex-col gap-4">
									<p>
										Under "settings", Enter your openAI API key (free on the open AI website) into the Api Key field and press the <span className="font-bold text-accent">Save</span> button. (You may need to refresh your browser
										for changes to take effect)
									</p>
									<p className="text-slate-300">
										To get an openAI Api access key, head over to their website{' '}
										<Link to="https://platform.openai.com/account/api-keys" target="_blank" className="link link-accent">
											here
										</Link>
										. You will need to sign up for an opanAI account to get access.
									</p>
								</div>
							</div>

							{/* 5 */}
							<div className="flex flex-row gap-10 items-center">
								<div className="w-24 h-24 shrink-0 rounded-full bg-primary text-4xl font-bold flex items-center justify-center">5</div>
								<div className="flex flex-col gap-4">
									<p>
										Now you're all set! Head over to any webpage, and in any text field start typing <span className="font-bold text-accent">gpt:</span> followed by any prompt you want to give it.{' '}
										<span className="font-bold">Aichatpilot</span> will automatically place the response right where you typed your question.
									</p>
									<p>
										Note that because of the way some webpages are designed, it's not possible for our extension to work in 100% of all text fields.{' '}
										<span className="italic text-slate-300">But we are actively trying to make Aichatpilot work absolutely everywhere!</span>{' '}
									</p>
									<p>
										In the few situations where it wont work directly on page, simply click the extension in the top right of chrome (you may need to click the Puzzle piece to see it) and there is a text field in there that you
										can enter your prompt into which you can simply copy and paste wherever needed.
									</p>
								</div>
							</div>
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default HowItWorks;
