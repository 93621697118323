import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { signInWithPopup } from 'firebase/auth';
import { auth, db, googleProvider } from '../../firebase.config';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
//---------------------------------------------------------------------------------------------------//
import AuthContext from '../../Context/AuthContext/AuthContext';
//---------------------------------------------------------------------------------------------------//
import MainContainer from '../../components/containers/MainContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import SectionContainer from '../../components/containers/SectionContainer';
import PageHeader from '../../components/header/PageHeader';
import Button from '../../components/buttons/Button';

//---------------------------------------------------------------------------------------------------//
function SignIn() {
	const { dispatchAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	/**
	 * handles logging in with Google
	 */
	const loginWithGoogle = async () => {
		try {
			const userCredential = await signInWithPopup(auth, googleProvider).catch(err => {
				console.log(err);
				return;
			});

			// Get user information.
			const uid = userCredential.user.uid;
			const name = userCredential.user.displayName;
			const email = userCredential.user.email;

			const userRef = doc(db, 'users', uid);
			const userSnap = await getDoc(userRef);

			// Check if the user exists
			if (userSnap.exists()) {
				dispatchAuth({
					type: 'LOGIN',
					payload: userSnap.data(),
				});
				navigate('/');
				return 'success';
			} else {
				await setDoc(doc(db, 'users', uid), {
					name,
					email,
					dateCreated: serverTimestamp(),
				});

				const newUser = await getDoc(doc(db, 'users', uid));
				dispatchAuth({
					type: 'LOGIN',
					payload: newUser.data(),
				});
				navigate('/');
			}
		} catch (error) {
			console.log(error);
		}
	};

	//---------------------------------------------------------------------------------------------------//
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Sign In</title>
				<link rel="canonical" href={`https://aichatpilot.com/signin`} />
			</Helmet>
			<MainContainer>
				<PageHeader text="Sign In" />
				<SectionWrapper>
					<SectionContainer margin="my-20">
						<div className="flex flex-row items-center justify-center w-full h-96">
							<Button text="Sign in" icon="google" onClick={loginWithGoogle} />
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default SignIn;
