import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/header/PageHeader';
import MainContainer from '../../components/containers/MainContainer';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import Button from '../../components/buttons/Button';

function CantFind() {
	const navigate = useNavigate();
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - 404</title>
				<link rel="canonical" href={`https://aichatpilot.com/404`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="404" />
				<SectionWrapper color="bg-base-300">
					<SectionContainer margin="m-20">
						<div className="flex flex-col gap-10 w-full h-full items-center justify-center">
							<h2>404 Can't Find</h2>
							<Button type="ahref" href="/" text="Return Home" icon="home" />
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default CantFind;
