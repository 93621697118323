import React, { useContext } from 'react';
import AuthContext from '../../Context/AuthContext/AuthContext';
import LogoText from '../../assets/logo-text.png';
import Hamburger from './Hamburger';
import { Link } from 'react-router-dom';
import Button from '../buttons/Button';

function Navbar() {
	const { user, authLoading } = useContext(AuthContext);

	return (
		<div className="navbar fixed top-0 left-0 z-50 w-full bg-base-100 md:bg-base-800 h-36 p-5 text-white items-center justify-center">
			<div className="w-full md:w-5/6 2xl:w-4/6 3xl:w-1/2">
				<div className="flex flex-row items-center flex-1 gap-5 lg:gap-10">
					<Link to="/" className="btn btn-ghost normal-case text-xl">
						<img src={LogoText} alt="ChatlystAi Logo" className="h-full" />
					</Link>

					<div className="hidden md:block">
						<ul className="menu menu-horizontal px-1 gap-5 lg:gap-10">
							<li>
								<Link to="/how-it-works">How it Works</Link>
							</li>
							<li>
								<Link to="/pricing">Pricing</Link>
							</li>
							<li>
								<Link to="/enterprise">Enterprise</Link>
							</li>
							<li>
								<Link to="/blog">Blog</Link>
							</li>
							{!authLoading && user && user?.siteAdmin && (
								<li>
									<Link to="/create-blog-post" className="btn-primary">
										Create Blog Post
									</Link>
								</li>
							)}
						</ul>
					</div>
				</div>
				<div className="hidden md:block flex-0">
					<Button text="Get Now" icon="right2" color="btn-primary" size="!btn-md lg:!btn-lg" type="ahref" href="https://chrome.google.com/webstore/detail/aichatpilot/fmbanjkafaebpafddbihomoeodjgaomk?hl=en&authuser=1" target="_blank" />
				</div>

				<Hamburger />
			</div>
		</div>
	);
}

export default Navbar;
