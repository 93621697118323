import React from 'react';

function SectionWrapper({ color, children }) {
	return (
		<div className={`flex flex-col w-full items-center ${color ? color : ''}`}>
			<div className="flex flex-col lg:flex-row w-full md:w-5/6 2xl:w-4/6 3xl:w-1/2">{children}</div>
		</div>
	);
}

export default SectionWrapper;
