import React from 'react';
import HeroVideoSmallWebm from '../../assets/video/hero_alpha_VP9_sm.webm';
import Button from '../buttons/Button';

function Hero() {
	return (
		<div className="flex flex-col w-full items-center justify-center">
			{/* vertical version */}
			<div className="block md:hidden flex flex-col w-full relative z-20">
				<div className="flex flex-col gap-24 w-full p-12 mt-10 mb-20">
					<div className="flex flex-col">
						<h1 className="text-white">The power of AI</h1>
						<h1 className="text-black">anywhere, anytime.</h1>
					</div>
					<p className="text-slate-200 leading-10 text-center">Access ChatGPT-3 on any website, at any time with our Google Chrome extension. Instantly boost your productivity with the world's most advanced language model.</p>
					<Button text="Get Now" icon="right2" color="bg-base-900" size="h-24" type="ahref" href="https://chrome.google.com/webstore/detail/aichatpilot/fmbanjkafaebpafddbihomoeodjgaomk?hl=en&authuser=1" target="_blank" />
				</div>

				<video loop="true" autoplay="autoplay" muted width="100%" className="z-10">
					<source src={HeroVideoSmallWebm} />
					Your browser does not support the video tag.
				</video>
			</div>

			{/* Horizontal ersion */}
			<div className="hidden md:flex flex-row w-full md:w-5/6 2xl:w-4/6 3xl:w-1/2 relative">
				<div className="flex flex-col gap-32 w-3/4 lg:w-1/2 p-12 mt-10 mb-20 z-20">
					<div className="flex flex-col">
						<h1 className="text-white">The power of AI</h1>
						<h1 className="text-black">anywhere, anytime.</h1>
					</div>
					<p className="text-slate-200 leading-10">Access ChatGPT-3 on any website, at any time with our Google Chrome extension. Instantly boost your productivity with the world's most advanced language model.</p>
					<Button text="Get Now" icon="right2" color="bg-base-900 text-white" size="h-24" type="ahref" href="https://chrome.google.com/webstore/detail/aichatpilot/fmbanjkafaebpafddbihomoeodjgaomk?hl=en&authuser=1" target="_blank" />
				</div>

				<video loop="true" autoplay="autoplay" muted width="100%" className="absolute h-full -right-1/2 lg:-right-1/4 top-0 p-12 z-10">
					<source src={HeroVideoSmallWebm} />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	);
}

export default Hero;
