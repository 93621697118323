import React from 'react';
import { Helmet } from 'react-helmet';
import MainContainer from '../../components/containers/MainContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import SectionContainer from '../../components/containers/SectionContainer';
import PageHeader from '../../components/header/PageHeader';
import EnterpriseGraphic from '../../assets/Enterprise.png';
import EnterpriseGraphic2 from '../../assets/Enterprise_2.png';
import Button from '../../components/buttons/Button';

function Enterprise() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Enterprise</title>
				<link rel="canonical" href={`https://aichatpilot.com/enterprise`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="Enterprise" />
				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="my-20">
						<div className="flex flex-row gap-10 items-center">
							<div className="flex flex-col gap-10">
								<h2 className="mb-2">Transform Your Enterprise Communications with AiChatPilot</h2>
								<p>
									Aichatpilot is an innovative tool that can help enterprises streamline their content creation process and improve their writing workflows. Our powerful AI-powered solution can be used in a variety of ways to help
									businesses achieve their goals.
								</p>
							</div>
							<img src={EnterpriseGraphic} alt="Enterprise" className="max-w-4xl" />
						</div>
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper color="bg-base-300">
					<SectionContainer margin="my-20">
						<div className="flex flex-row gap-10 items-center">
							<img src={EnterpriseGraphic2} alt="Enterprise" className="max-w-4xl" />

							<div className="flex flex-col gap-10">
								<h2>Benefits</h2>
								<p>
									<span className="font-bold text-accent"> Generating content quickly and easily:</span> Aichatpilot can help enterprises save time and effort by generating high-quality content in a matter of seconds.
								</p>
								<p>
									<span className="font-bold text-accent">Enhancing customer engagement:</span> Aichatpilot can help enterprises improve their customer engagement by providing personalized responses to customer inquiries. By using
									Aichatpilot, businesses can create conversational experiences that feel natural and authentic.
								</p>
								<p>
									<span className="font-bold text-accent">Scaling content creation efforts:</span> Aichatpilot can help enterprises scale their content creation efforts by generating large volumes of content quickly and efficiently.
									With Aichatpilot, businesses can create content at scale without sacrificing quality.
								</p>
							</div>
						</div>
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="my-20">
						<div className="flex flex-col gap-10 items-center justify-center">
							<p className="text-center">
								To learn more about how Aichatpilot can help your enterprise, please contact us at <span className="font-bold text-accent">enterprise@aichatpilot.com</span>. Our team is always available to answer your questions and
								help you get started with Aichatpilot.
							</p>
							<Button type="ahref" href="/contact" text="Contact" color="btn-primary" icon="email" size="w-fit" />
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Enterprise;
