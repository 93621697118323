import React from 'react';
import { Helmet } from 'react-helmet';
import Typewriter from 'typewriter-effect';
//---------------------------------------------------------------------------------------------------//
import MainContainer from '../../components/containers/MainContainer';
import Hero from '../../components/hero/Hero';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import AdsCopy from '../../assets/ads-copy.png';
import SocialCopy from '../../assets/social-copy.png';
import EmailCopy from '../../assets/email-copy.png';
import Button from '../../components/buttons/Button';
import Collapse from '../../components/collapse/Collapse';
import PromoMp41080 from '../../assets/video/Promo_Video_mp4.m4v';
import PromoWebm1080 from '../../assets/video/Promo_Video_webm.webm';
import PromoPoster from '../../assets/aichatpilot_videoThumb.png';

function Index() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Home</title>
				<link rel="canonical" href={`https://aichatpilot.com/`} />
			</Helmet>

			<MainContainer>
				<div className="flex flex-row w-full relative overflow-hidden">
					<Hero />
					<div className=" w-full h-screen bg-secondary rotate-45 absolute translate-y-5/7 -bottom-3/4 -left-2/4 z-0"></div>
					<div className=" w-full h-screen bg-primary rotate-45 absolute translate-y-1/4 -bottom-3/4 -left-2/4 z-0"></div>
				</div>

				<video loop="true" controls width="100%" poster={PromoPoster} className="w-full md:w-3/4 lg:w-5/6 2xl:w-4/6 3xl:w-1/2">
					<source src={PromoWebm1080} />
					<source src={PromoMp41080} />
					Your browser does not support the video tag.
				</video>

				<SectionWrapper color="bg-base-300">
					<SectionContainer padding="py-20">
						<div className="flex flex-row gap-10 items-center justify-center w-full bg-base-900 rounded-full py-6 border-2 lg:border-4 border-solid border-slate-700">
							<h2>gpt:</h2>
							<h2>
								<Typewriter
									options={{
										autoStart: true,
										loop: true,
										strings: [
											'Write me a cover letter',
											'Create a LinkedIn Post',
											'Come up with a Google Ad',
											'How do I write this in Javascript',
											'Proofread my post',
											'How do I do this in excel',
											'Write a tweet',
											'Write a reply to this post',
											'Write a blog post',
											'Comment on this video',
										],
										delay: 70,
									}}
								/>
							</h2>
						</div>
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper color="bg-base-300">
					<SectionContainer color="bg-base-300">
						<div className="flex flex-col gap-10 px-12 py-20 w-full">
							<h2 className="text-white">
								Write Better Content <span className="text-accent">Everywhere</span>
							</h2>
							<p>
								AiChatPilot uses ChatGPT to generate high-quality responses to your prompts <span className="font-bold text-accent">directly on any website</span>. Whether you're a blogger, social media manager, or just want to
								streamline your content creation, AiChatPilot can help you achieve your writing goals effortlessly.
							</p>
						</div>
					</SectionContainer>

					<SectionContainer color="bg-base-300" position="items-end justify-end">
						<img src={AdsCopy} className="w-full max-w-7xl" alt="Content for Ads" />
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper color="bg-base-300">
					<SectionContainer color="bg-base-300" display="lg:flex hidden">
						<img src={SocialCopy} className="w-full max-w-7xl" alt="Content for Social" />
					</SectionContainer>

					<SectionContainer color="bg-base-300">
						<div className="flex flex-col gap-10 px-12 py-20 w-full">
							<h2 className="text-right text-white">
								Save Time on <span className="text-accent">Content Creation</span>
							</h2>
							<p className="text-right">
								AiChatPilot streamlines content creation for businesses by allowing you to instantly generate content like ads, blog posts, and social media posts directly on any webpage, Saving you time and effort in content creation
								& freeing up resources to focus on whats important.
							</p>
						</div>
					</SectionContainer>

					<SectionContainer color="bg-base-300" display="flex lg:hidden">
						<img src={SocialCopy} className="w-full max-w-7xl" alt="Content for Social" />
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper color="bg-base-300">
					<SectionContainer color="bg-base-300">
						<div className="flex flex-col gap-10 px-12 py-20 w-full">
							<h2 className="text-white">
								<span className="text-accent"> Streamline</span> Your Writing Tasks
							</h2>
							<p>Aichatpilot can help both businesses and individuals with writing tasks such as composing emails, creating documents, and more, without having to leave the page.</p>
						</div>
					</SectionContainer>

					<SectionContainer color="bg-base-300" padding="pb-10" margin="mb-20">
						<img src={EmailCopy} className="w-full max-w-7xl" alt="Content for Social" />
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="mb-20">
						<div className="px-12 py-20 items-center flex flex-col w-full gap-20">
							<h2 className="text-white text-center">Perfect for Businesses & Individuals</h2>
							<p className="text-center">Aichatpilot is a powerful tool that benefits both businesses and individuals by simplifying content creation and optimizing writing tasks.</p>
							<div className="flex flex-col items-center gap-5 w-full">
								<Button
									text="Get Today"
									color="btn-primary"
									size="w-full lg:w-fit  h-28"
									margin="mb-4"
									icon="right2"
									type="ahref"
									href="https://chrome.google.com/webstore/detail/aichatpilot/fmbanjkafaebpafddbihomoeodjgaomk?hl=en&authuser=1"
									target="_blank"
								/>
								<Button type="ahref" href="/enterprise" text="Aichatpilot for Enterprise" color="btn-secondary" size="w-full lg:w-fit h-28" icon="building" />
							</div>
						</div>
					</SectionContainer>
				</SectionWrapper>

				<SectionWrapper color="bg-neutral">
					<SectionContainer color="bg-neutral" padding="pb-20 pt-10">
						<div className="px-12 py-2 items-center w-full flex flex-col">
							<h2 className="text-white text-center">FAQ</h2>

							{/* 1 */}
							<Collapse tabIndex={0} title="What is Aichatpilot and how does it work?">
								<p>Aichatpilot is a Google Chrome extension that allows users to access chatGPT-3 on any website, at any time. It works by taking in a user's prompt and using the chatGPT-3 language model to generate a response.</p>
								<p>
									<br />
									Aichatpilot can be used to generate content for various purposes such as emails, social media posts, blog articles, and more. Additionally, it can help streamline writing tasks and save time for both businesses and
									individual users.
								</p>
							</Collapse>

							{/* 2 */}
							<Collapse tabIndex={1} title="Does Aichatpilot work on all websites?">
								<p>Aichatpilot works on 99% of websites, but unfortunately there are some that it will not work on & we are actively trying to find ways to make Aichatpilot work on every website.</p>
								<p>
									<br />
									For those websites that it won't work on, you can open the Aichatpilot extension and enter your prompt there and copy the result to your desired location.
								</p>

								<p>
									<br />
									If there's a website that doesn't work that you'd like us to look into, please reach out through the contact page and we'll look into it.
								</p>
							</Collapse>

							<h5 className="mt-10 mb-5">Visit our FAQ page for more information</h5>
							<Button type="ahref" href="/faq" text="FAQ" icon="help" color="btn-primary" size="w-fit" />
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Index;
