import React from 'react';
import { BsCaretDownFill } from 'react-icons/bs';

function Collapse({ tabIndex, title, children }) {
	return (
		<div className="w-full py-4">
			<div tabIndex={tabIndex} className="collapse bg-base-100 rounded-box w-full cursor-pointer">
				{title && (
					<>
						<h4 className="collapse-title flex lg:hidden flex-row items-center h-fit py-8 place-content-between">
							{title}
							<span>
								<BsCaretDownFill />
							</span>
						</h4>
						<h5 className="collapse-title hidden lg:flex flex-row items-center h-fit py-8 place-content-between">
							{title}
							<span>
								<BsCaretDownFill />
							</span>
						</h5>
					</>
				)}
				<div className="collapse-content flex-flex-col w-full gap-20 text-slate-300 px-20">
					<div className="divider"></div>
					{children}
				</div>
			</div>
		</div>
	);
}

export default Collapse;
