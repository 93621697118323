import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../Context/AuthContext/AuthContext';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import MainContainer from '../../components/containers/MainContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import SectionContainer from '../../components/containers/SectionContainer';
import PageHeader from '../../components/header/PageHeader';
import { fetchBlogPost, deleteBlogPost } from '../../utilities/fetchBlogPost';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Button from '../../components/buttons/Button';

function Post() {
	const { user, authLoading } = useContext(AuthContext);
	const [post, setPost] = useState(null);
	const [loading, setLoading] = useState(true);
	// Fetch Post
	const params = useParams().id;
	const navigate = useNavigate();

	useEffect(() => {
		fetchBlogPost(params).then(fetchedPost => {
			fetchedPost.timestamp = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(fetchedPost.timestamp.seconds * 1000);
			fetchedPost.content = EditorState.createWithContent(convertFromRaw(JSON.parse(fetchedPost.content)));
			setPost(fetchedPost);

			setLoading(false);
		});
	}, []);

	const handleDeletePost = () => {
		deleteBlogPost(params);
		navigate('/blog');
	};

	//---------------------------------------------------------------------------------------------------//
	return (
		<>
			{!loading && (
				<>
					<Helmet>
						<meta charSet="utf-8" />
						<title>AiChatpilot - {post.title}</title>
						<link rel="canonical" href={`https://aichatpilot.com/blog/${params}`} />
					</Helmet>
					<MainContainer>
						<PageHeader text="Post Name" />
						<SectionWrapper>
							<SectionContainer margin="my-20" padding="p-10">
								{!loading && post && (
									<>
										<h1>{post.title}</h1>
										<p className="italic text-end text-slate-500">{post.timestamp}</p>
										<Editor editorState={post.content} readOnly={true} toolbarHidden={true} />
									</>
								)}
								{!authLoading && user && user.siteAdmin && <Button onClick={handleDeletePost} text="Delete Post" size="w-fit" icon="delete" />}
							</SectionContainer>
						</SectionWrapper>
					</MainContainer>
				</>
			)}
		</>
	);
}

export default Post;
