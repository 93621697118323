import React from 'react';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import PageHeader from '../../components/header/PageHeader';
import MainContainer from '../../components/containers/MainContainer';
import { Helmet } from 'react-helmet';

function Terms() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - Terms of Use</title>
				<link rel="canonical" href={`https://aichatpilot.com/terms`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="Terms and Conditions" />
				<SectionWrapper>
					<SectionContainer color="bg-base-100" margin="my-20">
						<div className="flex flex-col w-full gap-10">
							<h4>Effective March 20,2023</h4>
							<p>Acceptance of Terms: By installing and using Aichatpilot, you agree to be bound by these terms of use.</p>
							<p>
								<span className="font-bold">Use of Service:</span> Aichatpilot is provided for personal and non-commercial use only. You may not use Aichatpilot for any illegal or unauthorized purpose. You must not interfere with or
								disrupt the Aichatpilot service or servers or networks connected to the service, or disobey any requirements, procedures, policies, or regulations of networks connected to the service.
							</p>
							<p>
								<span className="font-bold">Modifications to Service:</span> Aichatpilot reserves the right to modify or discontinue the service with or without notice to you. Aichatpilot shall not be liable to you or any third party
								should Aichatpilot exercise its right to modify or discontinue the service.
							</p>
							<p>
								<span className="font-bold">Intellectual Property:</span> All intellectual property rights in and to the Aichatpilot service are owned by Aichatpilot or its licensors. You agree not to reproduce, duplicate, copy, sell,
								resell or exploit any portion of the Aichatpilot service.
							</p>
							<p>
								<span className="font-bold">Indemnity:</span> You agree to indemnify and hold Aichatpilot harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your
								use of the service, your violation of these terms of use, or your violation of any rights of another.
							</p>
							<p>
								<span className="font-bold">Disclaimer of Warranties:</span> Aichatpilot makes no warranty that the service will meet your requirements, that the service will be uninterrupted, timely, secure, or error-free, or that
								the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations.
							</p>
							<p>
								<span className="font-bold">Limitation of Liability:</span> Aichatpilot shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the service
								or for cost of procurement of substitute goods and services or resulting from any goods or services purchased or obtained or messages received or transactions entered into through the service or resulting from
								unauthorized access to or alteration of your transmissions or data.
							</p>
							<p>
								<span className="font-bold">Governing Law:</span> These terms of use shall be governed by and construed in accordance with the laws of the jurisdiction in which Aichatpilot operates. Any disputes arising from or
								related to these terms of use shall be subject to the exclusive jurisdiction of the courts located in that jurisdiction.
							</p>
							<p>
								<span className="font-bold">Termination:</span> Aichatpilot may terminate your access to the service at any time, with or without cause, with or without notice, effective immediately.
							</p>
							<p>
								<span className="font-bold">Entire Agreement:</span> These terms of use constitute the entire agreement between you and Aichatpilot and govern your use of the service.
							</p>
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Terms;
