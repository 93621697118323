import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Index, Privacy, Terms, Pricing, HowItWorks, Contact, MediaKit, Enterprise, Blog, Post, CreateBlog, SignIn, CantFind, Faq } from './pages';
import Navbar from './components/navbar/Navbar';
import Footer from './components/Footer/Footer';
import PrivateRoute from './routes/PrivateRoute';
import ContextsProvider from './Context/ContextsProvider';

/*
add some color/life to extension popup
*/

function App() {
	return (
		<Router>
			<ContextsProvider>
				<div className="w-screen overflow-hidden">
					<Navbar />
					<Routes>
						<Route exact path="/" element={<Index />} />
						<Route exact path="/privacy" element={<Privacy />} />
						<Route exact path="/terms" element={<Terms />} />
						<Route exact path="/pricing" element={<Pricing />} />
						<Route exact path="/pricing" element={<Pricing />} />
						<Route exact path="/how-it-works" element={<HowItWorks />} />
						<Route exact path="/contact" element={<Contact />} />
						<Route exact path="/signin" element={<SignIn />} />
						<Route exact path="/faq" element={<Faq />} />
						<Route exact path="/media-kit" element={<MediaKit />} />
						<Route exact path="/enterprise" element={<Enterprise />} />
						<Route exact path="/blog" element={<Blog />} />
						<Route
							exact
							path="/create-blog-post"
							element={
								<PrivateRoute>
									<CreateBlog />
								</PrivateRoute>
							}
						/>
						<Route exact path="/blog/:id" element={<Post />} />
						<Route exact path="/*" element={<CantFind />} />
					</Routes>
					<Footer />
				</div>
			</ContextsProvider>
		</Router>
	);
}

export default App;
