import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainContainer from '../../components/containers/MainContainer';
import SectionContainer from '../../components/containers/SectionContainer';
import SectionWrapper from '../../components/containers/SectionWrapper';
import PageHeader from '../../components/header/PageHeader';
import Collapse from '../../components/collapse/Collapse';
import Button from '../../components/buttons/Button';
import LogoIcon from '../../assets/logo-icon.png';
import Chrome from '../../assets/chrome_buy.png';

function Faq() {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>AiChatpilot - F.A.Q</title>
				<link rel="canonical" href={`https://aichatpilot.com/faq`} />
			</Helmet>

			<MainContainer>
				<PageHeader text="F.A.Q" />

				<SectionWrapper color="bg-neutral">
					<SectionContainer color="bg-neutral" padding="pb-20 pt-10">
						<div className="px-12 py-2 items-center w-full flex flex-col">
							<p>Here are some helpful answers to our most frequently asked questions.</p>
							<p className="pb-10">
								If you still need help please reach out through our contact form{' '}
								<Link to="/contact" className="link-secondary">
									here
								</Link>{' '}
							</p>

							{/* 1 */}
							<Collapse tabIndex={0} title="What is Aichatpilot and how does it work?">
								<p className="pb-10">
									Aichatpilot is a Google Chrome extension that allows users to access chatGPT-3 on any website, at any time. It works by taking in a user's prompt and using the chatGPT-3 language model to generate a response.
									<p>
										<br />
										Aichatpilot can be used to generate content for various purposes such as emails, social media posts, blog articles, and more. Additionally, it can help streamline writing tasks and save time for both businesses
										and individual users.
									</p>
								</p>
							</Collapse>

							{/* 2 */}
							<Collapse tabIndex={1} title="Does Aichatpilot work on all websites?">
								<p>Aichatpilot works on 99% of websites, but unfortunately there are some that it will not work on & we are actively trying to find ways to make Aichatpilot work on every website.</p>
								<p>
									<br />
									For those websites that it won't work on, you can open the Aichatpilot extension and enter your prompt there and copy the result to your desired location.
								</p>

								<p className="pb-10">
									<br />
									If there's a website that doesn't work that you'd like us to look into, please reach out through the contact page and we'll look into it.
								</p>
							</Collapse>

							{/* 3 */}
							<Collapse tabIndex={2} title="How accurate is Aichatpilot in generating content?">
								<p>
									Aichatpilot's accuracy in generating content depends on the accuracy of the chatGPT-3 language model, on which it is built. As chatGPT-3 is one of the most advanced language models available, Aichatpilot is capable
									of generating high-quality responses that are often indistinguishable from human-written text.
								</p>
								<p>
									<br />
									However, it's important to note that the accuracy of the responses can vary depending on the complexity of the task and the quality of the prompt provided by the user. Additionally, while chatGPT-3 has been trained
									on a vast corpus of text data, it may not be up-to-date on current events or specialized domains. As such, it's always a good idea to review and edit the generated text to ensure it meets your specific needs and
									standards.
								</p>

								<p className="pb-10">
									<br />
									Even though gpt-3 isn't up-to-date on current events, you can feed it a passage of text from an article of a recent event and gpt-3 will give its best response to the prompt it's given.
								</p>
							</Collapse>

							{/* 4 */}
							<Collapse tabIndex={3} title="Does Aichatpilot store any personal information?">
								<p className="pb-10">
									Aichatpilot does not store any personal information from users. All prompts from users are sent to our server (and never stored) and from there are routed straight to the openAI gpt-3 API to get a response.
									Additionally, Aichatpilot is committed to user privacy and does not sell or share any user data with third parties.
								</p>
							</Collapse>

							{/* 5 */}
							<Collapse tabIndex={4} title="Can Aichatpilot be customized to fit specific writing styles or brand guidelines?">
								<p>Aichatpilot uses the chatGPT-3 API to generate responses to user prompts, so it doesn't have any built-in customization features for specific writing styles or brand guidelines.</p>
								<p>
									<br />
									However, users can provide input prompts that reflect their desired writing style or brand tone, and Aichatpilot will generate responses based on that input.
								</p>

								<p className="pb-10">
									<br />
									For example, "write me a tweet in the style of a pirate" or "Write a professional business email".
								</p>
							</Collapse>

							{/* 6 */}
							<Collapse tabIndex={5} title="Is Aichatpilot easy to use for non-technical users?">
								<p>
									Yes, Aichatpilot is designed to be user-friendly and easy to use for non-technical users. The extension is installed like any other Google Chrome extension, and once installed, users can access Aichatpilot from any
									website.
								</p>

								<p>
									<br />
									To generate content, click in any text box and simply type in <span className="font-bold">gpt:</span> followed by any prompt or question, and Aichatpilot will generate a response based on that input. Once GPT-3
									sends a reply, the generated content is placed directly in the text box.
								</p>

								<p>
									<br />
									If typing on a websites input box isn't working, you can click the extension icon and enter a prompt inside of there. Once a reply is generate, users can easily copy and paste it into their desired location.
								</p>

								<p className="pb-10">
									<br /> Overall, Aichatpilot is designed to make content generation as easy and streamlined as possible, regardless of the user's technical expertise.
								</p>
							</Collapse>

							{/* 7 */}
							<Collapse tabIndex={6} title="How much does Aichatpilot cost?">
								<p>
									To use Aichatpilot, you will need to sign up for an OpenAI account, which comes with a free plan that has a limited amount of tokens for generating responses with chatGPT-3. However, if you need more access, you
									can upgrade to a paid tier which may cost you a fee.
								</p>

								<p>
									<br />
									Aichatpilot itself currently costs $4.99 per month on a subscription. We want to emphasize that any fees associated with using OpenAI's platform are separate from our extension, and we are not liable for any costs
									incurred beyond the subscription fee for Aichatpilot.{' '}
								</p>

								<p className="pb-10">
									<br /> Nonetheless, we strive to make Aichatpilot an affordable and accessible tool for all users, including those who may not be technically inclined.
								</p>
							</Collapse>

							{/* 8 */}
							<Collapse tabIndex={7} title="How do I subscribe/unsubscribe to AiChatPilot?">
								<h4>Subscribe</h4>
								<p>To subscribe to AiChatPilot, first you need to have the extension installed onto Google Chrome.</p>
								<p>
									<br />
									Once you have it installed, open the extension by clicking its icon in the upper right corner of Google Chrome. You may need to click the puzzle piece to open your extensions up and find it in there.
								</p>

								<p className="pb-10">
									<br />
									When the extension popup opens, there will be a button that says "Purchase to get started". Once you click that you will be prompted to subscribe. After you subscribe, you can refresh the page or close & re-open
									the extension to gain access.
								</p>
								<div className="divider"></div>
								<h4>Unsubscribe</h4>
								<p>To unsubscribe to AiChatPilot, first you need to have the extension installed onto Google Chrome.</p>
								<p>
									<br />
									Once you have it installed, open the extension by clicking its icon in the upper right corner of Google Chrome. You may need to click the puzzle piece to open your extensions up and find it in there.
								</p>
								<p className="pb-10">
									<br />
									When the extension popup opens, scroll to the bottom and there will be a button that says "Manage AiChatPilot Subscription". Once you click that you will be prompted with a window where you can unsubscribe.
								</p>
							</Collapse>

							{/* 9 */}
							<Collapse tabIndex={7} title="Are there any limitations to what Aichatpilot can do?">
								<p>
									Yes, there are some limitations to what Aichatpilot can do. Currently, the extension cannot read webpages and generate responses based on that information. It relies on users to input prompts and generate responses
									based on those prompts. (For example, you cant type in "Write me a response to the tweet above". You need to have the tweet itself as part of your prompt, such as "Write me a response to this tweet{' '}
									<span className="italic">"paste tweet here"</span> ")
								</p>

								<p className="pb-10">
									<br />
									Additionally, the accuracy of the responses generated depends on the quality and specificity of the prompts given. However, Aichatpilot is constantly improving and updates are made regularly to ensure the best
									possible experience for users.
								</p>
							</Collapse>

							{/* 10 */}
							<Collapse tabIndex={7} title="I've subscribed to AiChatPilot but when I type gpt: it says I'm not">
								<p>This can happen from time to time. Here are a few things you can try to fix it:</p>
								<br />

								<div className="flex flex-row gap-4">
									<p className="font-bold">1.</p>
									<div className="flex flex-col">
										<p>• Open up the extension by clicking the AiChatPilot icon in the upper right of Google Chrome. You may need to click the puzzle piece to open up your extensions and find it there.</p>
										<p>• When you open the extension, make sure you're logged in. If you're not logged in, you'll see a "Already purchased? Login" button.</p>
										<p>• You will only be able to login if you've subscribed to AiChatPilot. If you havent subscribed, make sure you do to gain access.</p>
									</div>
								</div>
								<div className="divider"></div>
								<div className="flex flex-row gap-4">
									<p className="font-bold">2.</p>
									<div className="flex flex-col">
										<p>• Try refreshing the page.</p>
										<p>• Try a hard page refresh with ctrl+f5.</p>
									</div>
								</div>
								<div className="divider"></div>
								<div className="flex flex-row gap-4">
									<p className="font-bold">3.</p>
									<div className="flex flex-col">
										<p>• At the bottom of the extension popup is a button that says "Manage AiChatPilot subscription".</p>
										<p>• Clicking that will open up a subscription management button. Make sure it says that you are actively subscribed.</p>
									</div>
								</div>
								<div className="divider"></div>
								<div className="flex flex-row gap-4">
									<p className="font-bold">4.</p>
									<div className="flex flex-col">
										<p>• If the issue still persists, please contact us through our contact form.</p>
									</div>
								</div>
							</Collapse>
						</div>
					</SectionContainer>
				</SectionWrapper>
			</MainContainer>
		</>
	);
}

export default Faq;
