import { cloneDeep } from 'lodash';
const AuthReducer = (state, action) => {
	switch (action.type) {
		case 'SET_AUTH':
			return {
				...state,
				...action.payload,
			};
		case 'LOGIN':
			return {
				...state,
				user: action.payload,
			};
		case 'LOGOUT':
			return {
				...state,
				user: null,
			};
		case 'UPDATE_USER':
			const getUserStateUpdate = cloneDeep(state.user);
			const newGetUserStateUpdate = Object.assign(getUserStateUpdate, action.payload);

			return {
				...state,
				user: newGetUserStateUpdate,
			};
		case 'SET_USER':
			const getCurrentUserState = { ...state.user };
			const updatedUserState = Object.assign(getCurrentUserState, action.payload);

			return {
				...state,
				user: updatedUserState,
				newUsername: updatedUserState?.username,
				newBio: updatedUserState?.bio,
			};
		case 'SET_FETCHING_PROFILE':
			return {
				...state,
				fetchingProfile: action.payload,
			};
		default:
			return state;
	}
};

export default AuthReducer;
